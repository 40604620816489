import { useEffect, useState } from 'react';
import { LogInput, LogsList, LogsMenu, LogsReport } from './components';
import { HttpLog, LogsSubScreen } from './types';

export const LogsScreen = () => {
  const [ logFile, setLogFile ] = useState<File>();

  const [ logs, setLogs ] = useState<HttpLog[]>( [] );

  const [ currentScreen, setCurrentScreen ] = useState<LogsSubScreen>( 'list' );

  useEffect( () => {
    if ( logFile ) {
      try {
        logFile.text().then( text => setLogs( JSON.parse( text ) ) );
      } catch {
        console.log( 'invalid json' );
      }
    }
  }, [ logFile ] );

  const screenRender = () => {
    if ( !logs.length ) return null;
    if ( currentScreen === 'list' ) return <LogsList logs={logs} />;
    if ( currentScreen === 'report' ) return <LogsReport logs={logs} />;
    return null;
  };

  return (
    <>
      <LogInput onConfirm={setLogFile} />
      {!!logs.length && (
        <>
          <LogsMenu
            currentScreen={currentScreen}
            onChange={setCurrentScreen}
          />
          {screenRender()}
        </>
      )}
    </>
  );
};
