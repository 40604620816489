import { useEffect, useState } from 'react';

const perPageVariants = [ 5, 10, 20 ];

export interface PagesPerRowProps {
  onChange: ( perPage: number ) => void
}

export const PagesPerRow = ( { onChange } : PagesPerRowProps ) => {

  const [ itemsPerPage, setItemsPerPage ] = useState( perPageVariants[1] );

  const onPerPageChange = ( perPage: number ) => () => {
    setItemsPerPage( perPage );
  };

  useEffect( () => {
    onChange( itemsPerPage );
  }, [ itemsPerPage ] );
  
  return (
    <div className="pagination-per-page">
      <span>Rows per page</span>
      <div>
        {perPageVariants.map( variant => (
          <span
            key={variant}
            className="per-page" 
            onClick={onPerPageChange( variant )}
            data-selected={itemsPerPage === variant}
          >
            {variant}
          </span>
        ) )}
      </div>
    </div>
  );
};