import { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { User } from '../../../services/models';
import { useDeleteUserMutation } from '../../../services/usersApi';
import { ModalForm } from '../ModalForm';

export interface UserEditModalProps {
  user: User | null;
  isOpen: boolean;
  onClose: () => void;
}

export const UserEditModal = ( { user, isOpen, onClose }: UserEditModalProps ) => {
  const [ deleteUser, { isSuccess, isError, reset } ] = useDeleteUserMutation();

  const onDelete = () => {
    if ( user?._id ) deleteUser( user._id );
  };

  useEffect( () => {
    if ( isSuccess ) toast.success( 'Deleted' );
    else if ( isError ) toast.error( 'Failed to delete' );
    if ( isSuccess || isError ) {
      onClose();
      reset();
    }
  }, [ isSuccess, isError ] );

  if ( !user ) return null;

  return (
    <ModalForm<Pick<User, 'name'>>
      initialValues={{
        name: user.name,
      }}
      isOpen={isOpen}
      onClose={onClose}
      labels={{
        name: 'Name',
      }}
      layout={[ [ 'name' ] ]}
      submitText='Update user'
      title='Edit user'
      validationSchema={{
        name: yup.string().required(),
      }}
      onDelete={onDelete}
    />
  );
};
