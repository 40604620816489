import { QueryDefinition } from '@reduxjs/toolkit/dist/query';
import { UseQuery } from '@reduxjs/toolkit/dist/query/react/buildHooks';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { debounce } from 'throttle-debounce';
import { tableListFiltersToControlsFilters } from '../../../utils';
import { ErrorBox } from '../Boxes/ErrorBox';
import { LoadingIndicatorBox } from '../Boxes/LoadingIndicatorBox';
import { SelectProps } from '../Controls/Select';
import { ListControls, ListControlsProps } from '../ListControls';
import { Pagination } from '../Pagination';
import { TableList, TableListProps } from './TableList';

export type TableListQueryProps<
  Item extends object,
  CustomFields extends string = never
> = Omit<TableListProps<Item, CustomFields>, 'items'> & {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  queryHook: UseQuery<QueryDefinition<void, any, any, Item[], any>>;
  searchFn?: ( term: string, items: Item[] ) => Item[];
  filters?: Record<string, TableListQueryFilterProps<Item>>;
  onItemsCountChange?: ( count: number ) => void;
};

export type TableListQueryFilterProps<Item> = Required<
  Pick<SelectProps<false>, 'options' | 'label' | 'defaultValue'>
> & {
  filterFn: ( val: string, items: Item[] ) => Item[];
};

export const TableListQuery = <Item extends object, CustomFields extends string = never>( {
  fieldsToDisplay,
  headings,
  queryHook,
  fieldTransformations,
  customFields,
  className,
  searchFn,
  filters,
  onItemsCountChange,
  onItemClick,
  editable,
  onEdit,
}: TableListQueryProps<Item, CustomFields> ) => {
  const { data, isLoading, isError, error, refetch } = queryHook();
  const [ filteredItems, setFilteredItems ] = useState( data );
  const [ paginatedItems, setPaginatedItems ] = useState( filteredItems );
  const [ appliedFilters, setAppliedFilters ] = useState<
    Record<string, Pick<TableListQueryFilterProps<Item>, 'filterFn'> & { val: string }>
  >( {} );

  useEffect( () => {
    if ( filteredItems?.length !== undefined && onItemsCountChange )
      onItemsCountChange( filteredItems.length );
  }, [ filteredItems ] );

  useEffect( () => {
    if ( !data ) return;
    let tmpItems: Item[] = data;
    for ( const filter of Object.values( appliedFilters ) ) {
      tmpItems = filter.filterFn( filter.val, tmpItems );
    }
    setFilteredItems( tmpItems );
  }, [ appliedFilters, data ] );

  const onFilter = useCallback(
    (
        filterName: string,
        filterProps: Pick<TableListQueryFilterProps<Item>, 'filterFn'>
      ) =>
      ( value: string ) => {
        setAppliedFilters( prevState => ( {
          ...prevState,
          [filterName]: { filterFn: filterProps.filterFn, val: value },
        } ) );
      },
    [ appliedFilters, setAppliedFilters ]
  );

  const onPaginate = ( items: Item[] ) => {
    setPaginatedItems( items );
  };

  const controlsFilters: ListControlsProps['filters'] = useMemo(
    tableListFiltersToControlsFilters( filters, onFilter ),
    [ filters ]
  );

  if ( isLoading ) return <LoadingIndicatorBox />;
  if ( isError )
    return (
      <ErrorBox
        error={error || 'Some error occurred'}
        possibleAction={{
          text: 'You can try to reload:',
          buttonText: 'Reload',
          onClick: () => {
            refetch();
          },
        }}
      />
    );

  return (
    <>
      <ListControls
        onSearch={searchFn && debounce( 100, onFilter( '__search', { filterFn: searchFn } ) )}
        filters={controlsFilters}
      />
      <div className='table-list-query'>
        <TableList
          items={paginatedItems || []}
          fieldsToDisplay={fieldsToDisplay}
          headings={headings}
          fieldTransformations={fieldTransformations}
          customFields={customFields}
          className={className}
          onItemClick={onItemClick}
          editable={editable}
          onEdit={onEdit}
        />
        <Pagination<Item>
          onPageChange={onPaginate}
          paginateItems={filteredItems || []}
        />
      </div>
    </>
  );
};
