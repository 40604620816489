import * as yup from 'yup';
import { LoginFormValues } from '../components/screens/LoginScreen';
import { TypedObjectShape } from './utils';

const loginFormSchemaObject : TypedObjectShape<LoginFormValues> = {
  login: yup.string().required( 'Required' ),
  password: yup.string().required( 'Required' )
}; 

export const loginFormSchema = yup.object().shape( loginFormSchemaObject );
