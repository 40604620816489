import styles from '../LogsScreen.module.scss';
import { LogsSubScreen } from '../types';

export interface LogsMenuProps {
  onChange: ( screen: LogsSubScreen ) => void;
  currentScreen: LogsSubScreen;
}

export const LogsMenu = ( { onChange, currentScreen }: LogsMenuProps ) => {
  const onClick = ( screen: LogsSubScreen ) => () => onChange( screen );

  return (
    <ul className={styles['menu']}>
      <li
        data-current={currentScreen === 'list'}
        onClick={onClick( 'list' )}
      >
        List
      </li>
      <li
        data-current={currentScreen === 'report'}
        onClick={onClick( 'report' )}
      >
        Report
      </li>
    </ul>
  );
};
