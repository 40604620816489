export interface UserFeatureWithVisibility {
  enabled: boolean;
  visible: boolean;
}

export type User = {
  phone: string;
  name: string;
  age: number;
  gender: string;
  termsAgreed: boolean;
  emailMarketing: boolean;
  about?: string;
  location?: string;
  education?: string;
  job?: string;
  features?: string[];
  interests?: string[];
  alcohol?: UserFeatureWithVisibility;
  smoking?: UserFeatureWithVisibility;
  registeredAt: Date;
  joinedEvents: string[];
  _id: string;
};

export type ServerResponse = {
  success: boolean;
  status: ServerStatus;
  message?: string;
};

export enum ServerStatus {
  SUCCESS = 1000,
  INVALID_CREDENTIALS = 1001,
  SAVING_ERROR = 1003,
  NOT_FOUND = 1004,
  UNKNOWN_ERROR = 1005,
}

export interface Event {
  title: string;
  description: string;
  start: Date;
  location: {
    lat: number;
    lng: number;
    title: string;
  };
  bannerColor: string;
  emoji: string;
  maxMembers: number;
  members: string[];
  author?: { phone: string; _id: string };
  _id: string;
  byoc?: {
    authorCrew: string;
    authorCrewGender: 'male' | 'female';
    targetCrewGender: 'male' | 'female';
  };
}

export interface Feedback {
  _id: string;
  action: string;
  content: string;
  user: Pick<User, '_id' | 'name'>;
  additional?: object;
  createdAt: Date;
}

export type FeedbackRemovedMember = Omit<Feedback, 'additional'> & {
  additional: {
    removedUser: Pick<User, '_id' | 'name'>;
    event: Event;
  };
};

export interface SuperUser {
  _id: string;
  user: User;
}
