import { DateTime } from 'luxon';
import { useCallback, useState } from 'react';
import { User } from '../../../services/models';
import { useGetUsersQuery } from '../../../services/usersApi';
import { UserEditModal } from '../../modal/EditModals/UserEditModal';
import { TableListQuery } from '../../ui/TableList/TableListQuery';

export const UsersScreen = () => {
  const [ editModalOpened, setEditModalOpened ] = useState( false );
  const [ userToEdit, setUserToEdit ] = useState<User | null>( null );

  const closeModal = useCallback( () => {
    setEditModalOpened( false );
  }, [ setEditModalOpened ] );

  return (
    <>
      <TableListQuery<User>
        queryHook={useGetUsersQuery}
        fieldsToDisplay={[
          'phone',
          'name',
          'gender',
          'age',
          'registeredAt',
          'emailMarketing',
        ]}
        headings={{
          phone: 'Phone',
          name: 'Name',
          gender: 'Gender',
          age: 'Age',
          registeredAt: 'Registration Date',
          emailMarketing: 'Email marketing',
        }}
        editable={true}
        onEdit={user => {
          setUserToEdit( user );
          setEditModalOpened( true );
        }}
        fieldTransformations={{
          registeredAt: date => {
            return DateTime.fromJSDate( date ).toFormat( 'ff' );
          },
          emailMarketing: agreed => ( agreed ? 'Yes' : 'No' ),
        }}
      />
      <UserEditModal
        isOpen={editModalOpened}
        onClose={closeModal}
        user={userToEdit}
      />
    </>
  );
};
