import { ChangeEvent } from 'react';


export interface SearchProps {
  onChange: ( term: string ) => void
}

export const Search = (
  { onChange } : SearchProps
) => {

  const inputId = `search${Math.random().toString( 16 ).slice( 2 )}`;

  const onSearchChange = ( e: ChangeEvent<HTMLInputElement> ) => {
    onChange( e.target.value );
  };

  return (
    <div className="text-input search">
      <label htmlFor={inputId}>Search</label>
      <input 
        type="text" 
        id={inputId} 
        placeholder="Search..."
        onChange={onSearchChange}
      />
    </div>
  );
};