import React from 'react';
import { Link } from 'react-router-dom';
import { concat } from '../../../../utils';
import styles from '../TableList.module.scss';

export interface TableListItemProps<
  Item extends object,
  CustomColumns extends string = never
> {
  item: Item;
  display: ( keyof Item | CustomColumns )[];
  transformations?: FieldTransformations<Item>;
  link?: ( item: Item ) => string;
  customColumns?: CustomColumns extends never
    ? never
    : Record<CustomColumns, ( item: Item ) => string | number | JSX.Element>;
}
export type FieldTransformation<Item extends object, key extends keyof Item> = (
  value: Item[key]
) => string | JSX.Element;
export type FieldTransformations<Item extends object> = Partial<{
  [key in keyof Item]: FieldTransformation<Item, key>;
}>;

export const TableListItem = <Item extends object, CustomColumns extends string = never>( {
  item,
  display,
  link,
  transformations,
  customColumns,
}: TableListItemProps<Item, CustomColumns> ) => {
  const getColumnValue = ( key: keyof Item | CustomColumns ) => {
    if ( Object.keys( item ).includes( key as string ) ) {
      const transformation = transformations?.[key as keyof Item];
      if ( transformation ) {
        return transformation( item[key as keyof Item] );
      } else {
        return item[key as keyof Item] as unknown as string;
      }
    } else {
      if ( customColumns && customColumns[key as string] ) {
        return customColumns[key as string]( item );
      } else {
        return '';
      }
    }
  };

  const Wrapper = link
    ? ( { children }: React.PropsWithChildren<Record<string, unknown>> ) => (
        <Link to={link( item )}>{children}</Link>
      )
    : React.Fragment;

  return (
    <tr className={concat( styles['item'], link ? styles['link'] : undefined )}>
      {display.map( ( key, index ) => (
        <td key={index}>
          <Wrapper>{getColumnValue( key )}</Wrapper>
        </td>
      ) )}
    </tr>
  );
};
