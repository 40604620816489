import '../../assets/css/components/modal/ModalForm.css';
import { Form, Formik } from 'formik';
import { TypedObjectShape } from '../../validation/utils';
import { LabeledTextInput } from '../forms/LabeledTextInput';
import { Modal, ModalProps } from './modalBase/Modal';
import { Submit } from '../forms/Submit';
import * as yup from 'yup';
import { ObjectShape } from 'yup/lib/object';

export type ModalFormFieldProps = { name: string, label: string };
export type ModalFormField = ( props: ModalFormFieldProps ) => JSX.Element;
export type ModalFormLayout<Fields> = ( keyof Fields )[][];
export type ModalFormCustomInputs<Fields> = Partial<{ [key in keyof Fields]: ModalFormField }>
export type ModalFormLabels<Fields> = Partial<{[key in keyof Fields]: string}>

export interface ModalFormProps<Fields,> extends ModalProps {
  initialValues: Fields,
  labels: ModalFormLabels<Fields>,
  layout: ModalFormLayout<Fields>,
  onSubmit?: ( values: Fields ) => void,
  validationSchema?: TypedObjectShape<Fields>,
  customInputs?: ModalFormCustomInputs<Fields>,
  submitText?: string,
  onDelete?: ( values: Fields ) => void
}

export type ModalFormBodyProps<Fields> = Pick<ModalFormProps<Fields>, 'layout' | 'labels' | 'customInputs' | 'onSubmit' >;

const ModalFormBody = <Fields,>( { labels, layout, customInputs, onSubmit } : ModalFormBodyProps<Fields> ) => {
  
  const haveCustomInputs = customInputs !== undefined;

  return (
    <>
      {layout.map( ( row, rowIndex ) => (
        <div className="row" key={rowIndex}>
          {row.map( ( field, index ) => (
            haveCustomInputs && customInputs[field]
            ? ( customInputs[field] as ( ( props: any ) => JSX.Element ) )( { 
              name: field as string, label: labels[field] || field as string 
            } )
            : <LabeledTextInput 
                disabled={!onSubmit} 
                key={index} 
                name={field as string} 
                label={labels[field] || field as string} 
              />
          ) )}
        </div>
      ) )}
    </>
  );
};

export const ModalForm = <Fields,>( 
  { initialValues, labels, layout, onSubmit, validationSchema, 
    customInputs, isOpen, onClose, title='', className, 
    submitText='Submit', onDelete
  } 
  : ModalFormProps<Fields>
) => {
  
  return (
    <Modal
      isOpen={isOpen}
      onClose={onClose}
      title={title}
      className={[ 'form', className ].join( ' ' )}
    >
      <Formik
        initialValues={initialValues}
        onSubmit={onSubmit || ( () => null )}
        validationSchema={validationSchema ? yup.object().shape( validationSchema as ObjectShape ) : null}
      >
        <Form>
          <ModalFormBody labels={labels} layout={layout} customInputs={customInputs} onSubmit={onSubmit} />
          {onSubmit && <Submit text={submitText} />}
          {onDelete && (
            <button 
              className='button' 
              onClick={( e ) => {
                e.preventDefault();
                onDelete( initialValues );
              }}
            >
              Delete
            </button>
          )}
        </Form>
      </Formik>
    </Modal>
  );
};