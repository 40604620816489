import { mainApi } from './mainApi';
import { SuperUser } from './models';
import { CreateSuperUserFields } from './types';

export const superUsersApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getSuperUsers: build.query<SuperUser[], void>( {
      query: () => ( {
        url: '/superUsers',
        method: 'get',
      } ),
      providesTags: [ 'superUsers' ],
    } ),
    createSuperUser: build.mutation<void, CreateSuperUserFields>( {
      query: fields => ( {
        url: '/superUsers',
        method: 'post',
        data: fields,
      } ),
      invalidatesTags: [ 'superUsers' ],
    } ),
    deleteSuperUser: build.mutation<void, string>( {
      query: id => ( {
        url: `/superUsers/${id}`,
        method: 'delete',
      } ),
      invalidatesTags: [ 'superUsers' ],
    } ),
  } ),
} );

export const {
  useGetSuperUsersQuery,
  useCreateSuperUserMutation,
  useLazyGetSuperUsersQuery,
  useDeleteSuperUserMutation,
} = superUsersApi;
