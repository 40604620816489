import { TableListHeader } from './TableListHeader';
import { TableListItem } from './TableListItem';
import '../../../assets/css/components/TableList.css';
import '../../../assets/css/components/ui/box.css';

export interface TableListProps<Item extends object, CustomFields extends string = never> {
  items: Item[],
  fieldsToDisplay: ( keyof Item | CustomFields )[],
  headings: Partial<{[key in keyof Item]: string} & {[key in CustomFields]: string}>,
  fieldTransformations?: FieldTransformations<Item>,
  onEdit?: ( value: Item ) => void,
  editable?: boolean,
  customFields?: Record<CustomFields, ( item: Item ) => string | JSX.Element>,
  className?: string,
  onItemClick?: ( item: Item ) => void,
}

export type FieldTransformation<Item extends object, key extends keyof Item> = ( value: Item[key] ) => string | JSX.Element;
export type FieldTransformations<Item extends object> = Partial<{
  [key in keyof Item]: FieldTransformation<Item, key>
}>;

export const TableList = <Item extends object, CustomFields extends string = never>( 
  { 
    items, fieldsToDisplay, headings, fieldTransformations, 
    onEdit, editable, className, customFields, onItemClick
  } : TableListProps<Item, CustomFields> 
) => (
  <table className={[ 'table-list', className ].join( ' ' )}>
    <TableListHeader headings={headings} fieldsToDisplay={fieldsToDisplay} editable={editable} />
    <tbody>
      {items.map( ( item, index ) => 
        <TableListItem<Item, CustomFields>
          key={index} 
          item={item} 
          fieldsToDisplay={fieldsToDisplay}
          fieldTransformations={fieldTransformations}
          onEdit={onEdit}
          editable={editable}
          customFields={customFields}
          onItemClick={onItemClick}
        /> 
      )}
    </tbody>
  </table>
);