
import { ListControlsProps } from '../components/ui/ListControls';
import { TableListQueryFilterProps } from '../components/ui/TableList/TableListQuery';

export const tableListFiltersToControlsFilters = <Item>(
  filters: Record<string, TableListQueryFilterProps<Item>> | undefined,
  onFilter: ( 
    filterName: string, 
    filterProps: Pick<TableListQueryFilterProps<Item>, 'filterFn'> 
  ) => ( val: string ) => void
) => () => {
  if ( !filters ) return undefined;
    const resultFilters : ListControlsProps['filters'] = {};
    for ( const [ filterName, filterProps ] of Object.entries( filters ) ) {
      resultFilters[filterName] = {
        onChange: onFilter( filterName, filterProps ),
        options: filterProps.options,
        big: true,
        label: filterProps.label,
        defaultValue: filterProps.defaultValue
      };
    }
    return resultFilters;
};