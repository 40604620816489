import { PropsWithChildren } from 'react';
import { concat } from '../../../utils';
import styles from './LabeledField.module.scss';

export type LabeledFieldInfoProps = PropsWithChildren<{
  label: string;
  className?: string;
}>;

export const LabeledFieldInfo = ( {
  label,
  children,
  className,
}: LabeledFieldInfoProps ) => {
  return (
    <div className={concat( styles['labeled-field'], className )}>
      <label>{label}</label>
      <div className={styles['content']}>{children}</div>
    </div>
  );
};
