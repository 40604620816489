import { Select, SelectProps } from '../Controls/Select/';
import './ListControls.sass';
import { Search } from './Search';

export interface ListControlsProps {
  onSearch?: ( term: string ) => void;
  filters?: Record<string, SelectProps<false>>;
}

export const ListControls = ( { filters, onSearch }: ListControlsProps ) => {
  return (
    <div className='list-controls'>
      {filters &&
        Object.values( filters ).map( ( props, index ) => (
          <Select
            key={index}
            {...props}
            big
          />
        ) )}
      {onSearch && <Search onChange={onSearch} />}
    </div>
  );
};
