import { Form, Formik } from 'formik';
import { useState } from 'react';
import { SuperUser } from '../../../services';
import {
  useCreateSuperUserMutation,
  useGetSuperUsersQuery,
} from '../../../services/superUsersApi';
import { LabeledTextInput } from '../../forms/LabeledTextInput';
import { Submit } from '../../forms/Submit';
import { Modal } from '../../modal/modalBase/Modal';
import { TableListQuery } from '../../ui/TableList/TableListQuery';
import { SuperUserActions } from './SuperUserActions';
import { UsersSelect } from './UsersSelect';

export const SuperUsersScreen = () => {
  const [ createSuperUser ] = useCreateSuperUserMutation();

  const [ isModalOpen, setModalOpen ] = useState( false );

  const toggleModalOpen = ( value: boolean ) => () => {
    setModalOpen( value );
  };

  const initialValues = {
    user: { value: '', label: 'Search for users' },
    password: randomString( 12 ),
  } as {
    user: { value: string; label: string };
    password: string;
  };

  const onSubmit = ( values: typeof initialValues ) => {
    createSuperUser( {
      uid: values.user.value,
      password: values.password,
    } )
      .unwrap()
      .then( () => setModalOpen( false ) );
  };

  return (
    <>
      <button
        className='button button-click-effect'
        style={{ width: 'auto' }}
        onClick={toggleModalOpen( true )}
      >
        Add Super User
      </button>
      <TableListQuery<SuperUser, 'actions'>
        queryHook={useGetSuperUsersQuery}
        fieldsToDisplay={[ 'user', 'actions' ]}
        headings={{ user: 'User', actions: 'Actions' }}
        fieldTransformations={{
          user: user => user.name,
        }}
        customFields={{
          actions: superUser => <SuperUserActions superUser={superUser} />,
        }}
      />
      <Modal
        isOpen={isModalOpen}
        onClose={toggleModalOpen( false )}
        title='Create super user'
      >
        <Formik
          initialValues={initialValues}
          onSubmit={onSubmit}
        >
          <Form style={{ minHeight: 400 }}>
            <UsersSelect />
            <LabeledTextInput
              name='password'
              label='Super Password (you wont be able to see it again)'
              disabled
            />
            <Submit text='Create' />
          </Form>
        </Formik>
      </Modal>
    </>
  );
};

function randomString( length: number ) {
  let result = '';
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789^%!&';
  const charactersLength = characters.length;
  for ( let i = 0; i < length; i++ ) {
    result += characters.charAt( Math.floor( Math.random() * charactersLength ) );
  }
  return result;
}
