import { mainApi } from './mainApi';
import { Event, ServerResponse } from './models';

export const eventsApi = mainApi.injectEndpoints( {
  endpoints: build => ( {
    getEvents: build.query<Event[], void>( {
      query: () => ( { url: '/events', method: 'get' } ),
      providesTags: [ 'events' ],
    } ),
    deleteEvent: build.mutation<ServerResponse, string>( {
      query: id => ( { url: `/events/${id}`, method: 'delete' } ),
      invalidatesTags: [ 'events' ],
    } ),
  } ),
} );

export const { useGetEventsQuery, useDeleteEventMutation } = eventsApi;
