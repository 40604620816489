import { useEffect } from 'react';
import toast from 'react-hot-toast';
import * as yup from 'yup';
import { useDeleteEventMutation } from '../../../services/eventsApi';
import { Event } from '../../../services/models';
import { ModalForm } from '../ModalForm';

export interface EventEditModalProps {
  event: Event | null;
  isOpen: boolean;
  onClose: () => void;
}

export const EventEditModal = ( { event, isOpen, onClose }: EventEditModalProps ) => {
  const [ deleteEvent, { isSuccess, isError, reset } ] = useDeleteEventMutation();

  useEffect( () => {
    if ( isSuccess ) toast.success( 'Deleted' );
    else if ( isError ) toast.error( 'Failed to delete' );
    if ( isSuccess || isError ) {
      onClose();
      reset();
    }
  }, [ isSuccess, isError ] );

  const onDelete = () => {
    if ( event ) deleteEvent( event._id );
  };

  if ( !event ) return null;

  return (
    <ModalForm<Pick<Event, 'title'>>
      initialValues={{
        title: event.title,
      }}
      isOpen={isOpen}
      onClose={onClose}
      labels={{
        title: 'Title',
      }}
      layout={[ [ 'title' ] ]}
      submitText='Update event'
      title='Edit event'
      validationSchema={{
        title: yup.string().required(),
      }}
      onDelete={onDelete}
    />
  );
};
