import { ChangeEventHandler, useState } from 'react';
import styles from '../LogsScreen.module.scss';

export interface LogInputProps {
  onConfirm: ( file: File ) => void;
}

export const LogInput = ( { onConfirm }: LogInputProps ) => {
  const [ file, setFile ] = useState<File>();

  const onInputChange: ChangeEventHandler<HTMLInputElement> = e => {
    const files = e.target.files;
    if ( files?.length ) setFile( files[0] );
  };

  const onClick = () => {
    if ( file ) {
      onConfirm( file );
    }
  };

  return (
    <div
      className={styles['log-input']}
      data-filled={!!file}
    >
      <label className={styles['input']}>
        {file ? file.name : 'Select log file'}
        <input
          hidden
          type='file'
          onChange={onInputChange}
          accept='application/json'
        />
      </label>
      <button
        className={[ 'button', styles['button'] ].join( ' ' )}
        disabled={!file}
        onClick={onClick}
      >
        Parse
      </button>
    </div>
  );
};
