import { TableListProps } from './TableList';

export type TableListHeaderProps<Item extends object, CustomFields extends string = never> = 
  Pick<TableListProps<Item, CustomFields>, 'headings' | 'fieldsToDisplay' | 'editable'>

export const TableListHeader = <Item extends object, CustomFields extends string = never,>( 
  { headings, fieldsToDisplay, editable } : TableListHeaderProps<Item, CustomFields> 
) => (
  <thead>
    <tr>
      { editable && <td className='controls-container'></td> }
      {fieldsToDisplay.map( ( field, index ) => 
        <th key={index}>{headings[field]}</th> 
      )}
    </tr>
  </thead>
);