import { Feedback, FeedbackRemovedMember } from './models';

export const isRemoveMemberFeedback = ( o: Feedback ): o is FeedbackRemovedMember => {
  return o.action === 'removeEventMember';
};

export const isRemoveMemberFeedbackInfo = (
  o: unknown
): o is FeedbackRemovedMember['additional'] => {
  return (
    ( o as FeedbackRemovedMember['additional'] ).event !== undefined &&
    ( o as FeedbackRemovedMember['additional'] ).removedUser !== undefined
  );
};
