

export interface ModalBodyProps {
  children: React.ReactNode
}

export const ModalBody = ( { children } : ModalBodyProps ) => (
  <div className="modal-body">
    {children}
  </div>
);