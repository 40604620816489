import { DateTime } from 'luxon';
import { Feedback, isRemoveMemberFeedbackInfo } from '../../services';
import { useGetFeedbacksQuery } from '../../services/feedbackApi';
import { TableListQuery } from '../ui/TableList/TableListQuery';

export const FeedbackScreen = () => {
  return (
    <TableListQuery<Feedback, never>
      fieldsToDisplay={[ 'user', 'action', 'createdAt', 'content', 'additional' ]}
      headings={{
        user: 'User',
        action: 'Action',
        createdAt: 'Created At',
        content: 'Content',
        additional: 'Info',
      }}
      queryHook={useGetFeedbacksQuery.bind( null, undefined ) as never}
      fieldTransformations={{
        user: user => user.name,
        createdAt: date => DateTime.fromJSDate( date ).toFormat( 'f' ),
        additional: info =>
          isRemoveMemberFeedbackInfo( info ) ? (
            <>
              Event Name: {info.event.title}
              <br />
              Removed User: {info.removedUser.name}
            </>
          ) : (
            ''
          ),
      }}
    />
  );
};
