import { useMemo } from 'react';
import { concat } from '../../../utils';
import styles from './Button.module.scss';

export interface ButtonProps {
  text: string;
  onClick?: () => void;
  className?: string;
  small?: boolean;
}

export const Button = ( { text, className, onClick, small }: ButtonProps ) => {
  const buttonClassName = useMemo( () => {
    return concat( styles['button'], className, small ? styles['small'] : undefined );
  }, [ small, className ] );

  return (
    <button
      onClick={onClick}
      className={buttonClassName}
    >
      {text}
    </button>
  );
};
